import React from "react";
import "./horizontal-bar-full.styles.scss";

const HorizontalBarFull = props => {
  return (
    <div
      className={`horizontal-bar-full
            ${props.fixedTop ? "horizontal-bar-full__fixed-top" : ""}
            ${
              props.fixedTopRowTwo
                ? "horizontal-bar-full__fixed-top-row-two"
                : ""
            }
            `}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default HorizontalBarFull;
