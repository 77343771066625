import { db } from '../firebase/firebase.utils';
import firebase from 'firebase';
import { keys } from '@material-ui/core/styles/createBreakpoints';

//START---------------
export const sortScoresDesc = (currentScores, objLocation) => {
  const newSort = currentScores.sort((first, sec) => {
    return first[objLocation] > sec[objLocation] ? -1 : 1;
  });
  return newSort;
};
//END---------------------

//START---------------
export const sortScoresAsc = (currentScores, objLocation) => {
  const newSort = currentScores.sort((first, sec) => {
    return first[objLocation] < sec[objLocation] ? -1 : 1;
  });
  return newSort;
};
//END---------------------

//START---------------------------------------

export const checkUserCount = async () => {
  const userIdCount = await db
    .doc(`jpsCore/userIdCount`)
    .get()
    .then(function(doc) {
      if (doc.exists) {
        const data = doc.data();
        db.collection(`jpsCore`)
          .doc(`userIdCount`)
          .update({
            count: firebase.firestore.FieldValue.increment(1),
          });
        return data.count;
      } else {
        console.log('No such document!');
        db.collection(`jpsCore`)
          .doc(`userIdCount`)
          .set({
            count: firebase.firestore.FieldValue.increment(1),
          });
        return 0;
      }
    })
    .catch(function(error) {
      alert(
        'Error creating User ID; refresh page and if you have problems contact Daniel:',
        error
      );
    });

  return userIdCount;
};
//END------------------------------------
export const getGameResults = (
  filteredGamesToUpdate,
  nflWeekNum,
  seasonYear
) => {
  console.log('running GAME REsutls yes mananan');
  const results = filteredGamesToUpdate.map((game) => {
    const isGameOver =
      game.schedule.playedStatus === 'COMPLETED' ? true : false;
    const isGamePostponed =
      game.schedule.scheduleStatus === 'POSTPONED' &&
      game.schedule.week !== nflWeekNum
        ? true
        : false;
    const isCurrentWeek = game.schedule.week === nflWeekNum ? true : false;

    const winningId =
      isGameOver === false
        ? null
        : game.score.homeScoreTotal === game.score.awayScoreTotal
        ? 0
        : game.score.homeScoreTotal > game.score.awayScoreTotal
        ? game.schedule.homeTeam.id
        : game.score.awayScoreTotal > game.score.homeScoreTotal
        ? game.schedule.awayTeam.id
        : null;

    const losingId =
      isGameOver === false
        ? null
        : game.score.homeScoreTotal === game.score.awayScoreTotal
        ? 0
        : game.score.homeScoreTotal < game.score.awayScoreTotal
        ? game.schedule.homeTeam.id
        : game.score.homeScoreTotal > game.score.awayScoreTotal
        ? game.schedule.awayTeam.id
        : null;

    const winningTeam =
      isGameOver === false
        ? null
        : game.score.homeScoreTotal === game.score.awayScoreTotal
        ? 'TIE'
        : game.score.homeScoreTotal > game.score.awayScoreTotal
        ? game.schedule.homeTeam.abbreviation
        : game.schedule.awayTeam.abbreviation;

    const losingTeam =
      isGameOver === false
        ? null
        : game.score.homeScoreTotal === game.score.awayScoreTotal
        ? 'TIE'
        : game.score.homeScoreTotal < game.score.awayScoreTotal
        ? game.schedule.homeTeam.abbreviation
        : game.schedule.awayTeam.abbreviation;

    const gameId = game.schedule.id;

    const gameComplete =
      game.schedule.playedStatus === 'COMPLETED'
        ? true
        : game.schedule.scheduleStatus === 'POSTPONED' &&
          game.schedule.week !== nflWeekNum
        ? null
        : false;
    return {
      winningId,
      winningTeam,
      losingId,
      losingTeam,
      gameId,
      gameComplete,
      isGamePostponed,
      isCurrentWeek,
    };
  });

  const arrayOfWinningIds = results.map((each) => {
    if (each.isGamePostponed === false && each.isCurrentWeek) {
      return each.winningId;
    } else if (each.isGamePostponed) {
      return null;
    }
  });
  const arrayOfLosingIds = results.map((each) => {
    if (each.isGamePostponed === false && each.isCurrentWeek) {
      return each.losingId;
    } else if (each.isGamePostponed) {
      return null;
    }
  });

  const gamesLeftInWeek = results.filter((game) => {
    return game.gameComplete === false;
  });
  const gamesCompleted = results.filter((game) => {
    return game.gameComplete === true;
  });
  const postponedGamesFilter = results.filter((game) => {
    return game.isGamePostponed === true;
  });
  const postponedGames = postponedGamesFilter.length;

  const resultsInfo = {
    week: nflWeekNum,
    postponedGames,
    sportsLeague: 'NFL',
    seasonYear: seasonYear,
    gamesLeftInWeek: gamesLeftInWeek.length,
    gamesCompletedInWeek: gamesCompleted.length - postponedGames,
    gamesScheduledInWeek: gamesCompleted.length,
    arrayOfLosingIds,
    arrayOfWinningIds,
    lastUpdate: new Date(),
    ...results,
  };

  return resultsInfo;
};

//START--------------------------------

export const updateGameResults = async (nflGames, seasonYear) => {
  if (nflGames) {
    const nflCurrentWeekGame = nflGames.games.find((game) => {
      return game.schedule.scheduleStatus === 'NORMAL';
    });
    const nflWeekNum = nflCurrentWeekGame.schedule.week;

    const filterForNonPostponedGames = nflGames.games.filter((game) => {
      return (
        game.schedule.scheduleStatus === 'NORMAL' ||
        game.schedule.week === nflWeekNum
      );
    });

    const filterForGamesNotComplete = filterForNonPostponedGames.filter(
      (game) => {
        return (
          game.schedule.playedStatus === 'UNPLAYED' ||
          (game.schedule.scheduleStatus === 'POSTPONED' &&
            game.schedule.week !== nflWeekNum)
        );
      }
    );

    const winningIdColRef = db.collection(
      `/colAdditionalGameData/docGameResults/season-${seasonYear}`
    );

    const dbGamesLeftInWeekInDb = await winningIdColRef
      .doc(`week${nflWeekNum}`)
      .get()
      .then((docWeekDataRef) => {
        if (docWeekDataRef.exists) {
          const result = docWeekDataRef.get(`gamesLeftInWeek`);
          if (typeof result === 'undefined') {
            return null;
          } else return result;
        } else {
          console.log('NO im not here');
          return null;
        }
      })
      .catch(function(error) {
        alert(
          'Could not get DB games left data',
          error,
          console.log('ERROR', error)
        );
      });

    const needToUpdate =
      filterForGamesNotComplete.length === dbGamesLeftInWeekInDb ? false : true;

    if (needToUpdate) {
      const gameResults = getGameResults(
        nflGames.games,
        nflWeekNum,
        seasonYear
      );

      winningIdColRef
        .doc(`week${nflWeekNum}`)
        .set({
          ...gameResults,
        })
        .catch(function(error) {
          alert(
            'ERROR UPDATING gameResults data',
            error,
            console.log('ERROR', error)
          );
        });
    } else if (
      dbGamesLeftInWeekInDb === null &&
      typeof seasonYear !== 'undefined' &&
      typeof nflgames !== 'undefined'
    ) {
      const gameResults = getGameResults(
        nflGames.games,
        nflWeekNum,
        seasonYear
      );

      winningIdColRef
        .where('week', '==', nflWeekNum)
        .limit(1)
        .get()
        .then((queryWeekWinningIds) => {
          if (queryWeekWinningIds.empty === false) {
            console.log('im full of it!!');
            winningIdColRef.doc(`week${nflWeekNum}`).update({
              ...gameResults,
            });
          } else if (queryWeekWinningIds.empty === true) {
            winningIdColRef.doc(`week${nflWeekNum}`).set({
              ...gameResults,
            });
          }
        })
        .catch(function(error) {
          alert(
            'ERROR updating Winning IDs please inform Daniel if after reload this message persists',
            error,
            console.log('ERROR', error)
          );
        });
    } else {
      console.log('WE goood BRUH');
    }
  }
};

//-END--------------------------------

//-------START-----------------------------------------------------------------------------------------
export const addPickToDbIfAble = (gameDataObj) => {
  const userEmailId = gameDataObj ? gameDataObj.userEmailId : null;
  const userId = gameDataObj ? parseInt(gameDataObj.userId) : null;
  const gameId = gameDataObj ? parseInt(gameDataObj.gameId) : null;

  const selectedTeamId = gameDataObj
    ? parseInt(gameDataObj.selectedTeamId)
    : null;

  const selectedPoints = gameDataObj
    ? parseInt(gameDataObj.selectedPoints)
    : null;

  const season = gameDataObj ? parseInt(gameDataObj.season) : null;
  const weekNum = gameDataObj ? parseInt(gameDataObj.weekNum) : null;
  const gameSavedDbRef = db.collection(
    `userPickSelections/${userId}/season-${season}/week${weekNum}/userPicks`
  );
  const seasonRef = db.collection(
    `userPickSelections/${userId}/season-${season}`
  );
  const userIdRef = db.collection(`userPickSelections`);

  console.log('RUN');

  //creates userRef if not created
  if (userEmailId && userId) {
    userIdRef
      .where('userId', '==', userId)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty === true) {
          console.log('ID NOT SAVED');
          userIdRef.doc(`${userId}`).set({
            userId: userId,
            userEmail: userEmailId,
          });
        }
      });
  }

  //creates Week doc if not present
  seasonRef
    .where('weekNum', '==', weekNum)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty === true) {
        seasonRef.doc(`week${weekNum}`).set(
          {
            week: weekNum,
          },
          { merge: true }
        );
      }
    });

  if (
    gameDataObj &&
    userEmailId &&
    userId &&
    gameId &&
    selectedTeamId &&
    season &&
    selectedPoints &&
    weekNum
  ) {
    //checks if game has been saved in DB
    gameSavedDbRef
      .where('gameId', '==', gameId)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty === true) {
          console.log('yessss');
          gameSavedDbRef.add({
            selectedPoints: selectedPoints,
            selectedTeamId: selectedTeamId,
            gameId: gameId,
            pickTime: new Date(),
          });
        }

        if (querySnapshot.empty === false) {
          //GAME IS IN DB
          const matchedGameDoc = querySnapshot.docs[0].id;
          const dbData = querySnapshot.docs[0].data();
          const dbSelectedTeamId = parseInt(dbData.selectedTeamId);
          const dbSelectedPoints = parseInt(dbData.selectedPoints);

          if (parseInt(selectedTeamId) !== parseInt(dbSelectedTeamId)) {
            //write new team check for same points selected

            gameSavedDbRef
              .doc(`${matchedGameDoc}`)
              .set(
                {
                  selectedTeamId,
                  pickTime: new Date(),
                },
                { merge: true }
              )
              .catch((error) => {
                alert(
                  `error saving pick with ${selectedPoints} pts. please try again`,
                  error
                );
              });
          }

          if (parseInt(selectedPoints) !== parseInt(dbSelectedPoints)) {
            //writing New Team || New Points

            gameSavedDbRef
              .doc(`${matchedGameDoc}`)
              .set(
                {
                  selectedPoints: selectedPoints,
                  pickTime: new Date(),
                },
                { merge: true }
              )
              .catch((error) => {
                alert(
                  `error saving pick with ${selectedPoints} pts. please try again`,
                  error
                );
              });
          }

          if (parseInt(selectedPoints) === parseInt(dbSelectedPoints)) {
            console.log('NO NEED to update points');
          }
        }
      });
  }
};

//----START-----------------------------------------------------------
export const deletePicksFromDb = async (
  userId,
  listOfGames,
  season,
  focusWeek
) => {
  const weekRef = db.collection(
    `userPickSelections/${userId}/season-${season}/week${focusWeek}/userPicks`
  );

  const logArray = listOfGames.map((eachGame) => {
    return eachGame.gameId;
  });

  await weekRef.get().then((querySnapshot) => {
    querySnapshot.forEach((game) => {
      const dbGameId = game.data().gameId;
      const findMatchIndex = logArray.findIndex(
        (logGameId) => String(logGameId) === String(dbGameId)
      );
      if (findMatchIndex > -1) {
        return false;
      }
      if (findMatchIndex === -1) {
        weekRef
          .where('gameId', '==', dbGameId)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty === false) {
              const docIdToDelete = querySnapshot.docs[0].id;
              weekRef
                .doc(`${docIdToDelete}`)
                .delete()
                .catch((error) =>
                  alert(`
                  There was an error deleting your pick with selectedPoints
                  ${querySnapshot.docs[0].data().selectedPoints}
                  `)
                );
            }
          });
      }
    });
  });

  return null;
};

//START--------------------------------
export const getUserWinLossRecord = async (userId, groupId, seasonYear) => {
  db.collection(`userStats`)
    .where('userId', '==', userId)
    .get()
    .then((querySnapShot) => {
      if (querySnapShot.empty === false) {
        querySnapShot.docs[0].ref
          .collection(`NFL`)
          .where('isSeasonData', '==', true)
          .where('seasonYear', '==', seasonYear)
          .where('groupId', '==', groupId)
          .get()
          .then((querySnapShot) => {
            if (querySnapShot.empty === false) {
              return querySnapShot.docs[0].data();
            }
          });
      }
    });
};

//END--------------------------------

//START--------------------------------
export const getUserScoresFromEachWeek = async (
  userId,
  groupId,
  seasonYear
) => {
  const groupPreferences = await db
    .collection(`groupIds`)
    .where('groupId', '==', groupId)
    .get()
    .then((queryGroup) => {
      if (queryGroup.empty === false) {
        return queryGroup.docs[0].data().preferences;
      }
    });
  
  

  const weeklyPickData = await db
    .collection(`userStats`)
    .where('userId', '==', userId)
    .get()
    .then(async (querySnapShot) => {
      if (querySnapShot.empty === false) {
        console.log('YES ITS FALSE');
        const data = await querySnapShot.docs[0].ref
          .collection(`NFL`)
          .where('isWeekData', '==', true)
          .where('groupId', '==', groupId)
          .where('seasonYear', '==', seasonYear)
          .get()
          .then((queryNfl) => {
            if (queryNfl.empty === false) {
              const queryData = queryNfl.docs.map((each) => {
                return each.data();
              });
              return queryData;
            }
          });
        return data;
      }
    });
  //returns null if there is no user data for week.   
  if (typeof weeklyPickData == "undefined") {
    return null;
  }

  console.log(
    'INSIDE level 2 weeklypickdata: ',
    weeklyPickData,
    `WITH USERID: ${userId} `
  );

  const final = sortScoresDesc(weeklyPickData, 'totalPointsWon');
  console.log('WEEEKLY RAW DATA', final);

  const finalValues = final.map((eachWeekPicks, ind, arr) => {
    console.log("SIT ONE: ", groupPreferences.droppedWeeks)
    if (arr.length < groupPreferences.droppedWeeks) {
      return {
        droppedWeek: false,
        totalPointsWon: eachWeekPicks.totalPointsWon,
        wins: eachWeekPicks.gamesWon,
        losses: eachWeekPicks.gamesLost,
        amtGamesPicked: eachWeekPicks.pointsPlacedOnTeams.length,
        weekNum: eachWeekPicks.week,
      };
    }
    if (ind < arr.length - groupPreferences.droppedWeeks) {
      return {
        droppedWeek: false,
        totalPointsWon: eachWeekPicks.totalPointsWon,
        wins: eachWeekPicks.gamesWon,
        losses: eachWeekPicks.gamesLost,
        amtGamesPicked: eachWeekPicks.pointsPlacedOnTeams.length,
        weekNum: eachWeekPicks.week,
      };
    } else
      return {
        droppedWeek: true,
        totalPointsWon: eachWeekPicks.totalPointsWon,
        wins: eachWeekPicks.gamesWon,
        losses: eachWeekPicks.gamesLost,
        amtGamesPicked: eachWeekPicks.pointsPlacedOnTeams.length,
        weekNum: eachWeekPicks.week,
      };
  });

  return finalValues;
};

//END--------------------------------

//START--------------------------------
export const updateUserDataTotals = async (
  userId,
  groupId,
  seasonYear,
  nflWeekData
) => {
  // const userWinLossData = await getUserWinLossRecord(userId, groupId, seasonYear);
  const pointWeeklyData = await getUserScoresFromEachWeek(
    userId,
    groupId,
    seasonYear,
  );
if (pointWeeklyData != null){

  const final = pointWeeklyData.sort((first, sec) => {
    return first.weekNum < sec.weekNum ? -1 : 1;
  });
  return [...final];
};
};

//END--------------------------------

//START--------------------------------
export const getUserTotalsFromDb = async (userId, seasonYear) => {
  const userTotals = await db
    .doc(`userStats/${userId}/season-${seasonYear}/totals`)
    .onSnapshot((data) => {
      const userData = data.data();
      return userData;
    });

  return userTotals;
};
//END--------------------------------

//START--------------------------------
export const getGroupPicksFromWeek = async (weekNum, seasonYear) => {
  const listOfUserIds = await db
    .collection(`users`)
    .get()
    .then(async (querySnapShot) => {
      const userIds = querySnapShot.docs.map(async (eachUser) => {
        const userIdData = await eachUser.get(`userId`);
        const teamNameData = await eachUser.get(`teamName`);
        const userId = typeof userIdData === 'undefined' ? null : userIdData;
        const teamName =
          typeof teamNameData === 'undefined'
            ? 'noTeamName'
            : typeof teamNameData !== 'undefined'
            ? teamNameData
            : 'TBD';

        if (userId !== null) {
          return { userId, teamName };
        }
      });
      const listOfUserIds = await Promise.all(
        userIds.map((each) => {
          return each;
        })
      );
      return listOfUserIds;
    });
  const pickData = listOfUserIds.map(async (userData) => {
    const userPicks = await db
      .collection(
        `userPickSelections/${userData.userId}/season-${seasonYear}/week${weekNum}/userPicks`
      )
      .get()
      .then((userPickSnapshot) => {
        const userPicks = userPickSnapshot.docs.map((eachGamePicked) => {
          return eachGamePicked.data();
        });

        return userPicks;
      });
    const picks = await Promise.all(
      userPicks.map(async (pick) => {
        return pick;
      })
    );
    return { userId: userData.userId, teamName: userData.teamName, picks };
  });

  const finalPicks = await Promise.all(
    pickData.map((value) => {
      return value;
    })
  );
  return finalPicks;
};
//END--------------------------------

//START------------------------------------

export const getGroupPreferences = async (groupId, seasonYear) => {
  const groupPreferences = await db
    .collection(`groupIds`)
    .where('groupId', '==', groupId)
    // .where('seasonYear', '==', seasonYear)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty === false) {
        return querySnapshot.docs[0].data().preferences;
      }
    });

  return groupPreferences;
};

//END---------------------------------------

//START-----------------------------------------
export const getTeamNameFromGroupId = async (userId, groupId) => {
  const teamName = await db
    .collection(`users`)
    .where('userId', '==', userId)
    .get()
    .then((query) => {
      if (query.empty === true) {
        alert(`Searching users and didn't find your ID: ${userId}`);
      } else if (query.empty === false) {
        const teamNameList = query.docs[0].get(`teamNamesByGroupId`);

        if (typeof teamNameList === 'undefined') {
          const teamName = query.docs[0].get(`teamName`);
          return teamName;
        } else {
          const team = teamNameList.find((each) => {
            return each.groupId === groupId;
          });
          return team.teamName;
        }
      }
    });
  return teamName;
};

//END---------------------------------------------

//START------------------------------------------------------------------------

export const updateStandingsData = async (groupId, seasonYear) => {
  try {
    const nflWeekStatus = await db
      .doc(`/colAdditionalGameData/docWeeks/season-${seasonYear}/weekStatus`)
      .get()
      .then((docWk) => {
        const week = docWk.data();
        if (typeof week !== 'undefined') {
          return week;
        } else return null;
      });
    const groupPreferences = await getGroupPreferences(groupId, seasonYear);

    if (nflWeekStatus !== null) {
      const nflWkNum = nflWeekStatus.currentNflWeekNum;
      const finalWkNum = nflWeekStatus.currentFinalWeek;

      console.log('standings');
      const listOfUserIds = await db
        .collection(`userPickSelections`)
        .get()
        .then((querySnapshot) => {
          const userIds = querySnapshot.docs.map((userDoc) => {
            const userInfo = userDoc.data();
            if (typeof userInfo['userId'] !== 'undefined') {
              return userInfo.userId;
            } else return null;
          });
          const finalList = userIds.filter((each) => {
            return each !== null;
          });

          return finalList;
        });
        console.log("USER ID LIST", listOfUserIds)
      const standingsData =   listOfUserIds.map(async (userId) => {
        const teamName = await getTeamNameFromGroupId(userId, groupId);
        const userData = await getUserScoresFromEachWeek(
          userId,
          groupId,
          seasonYear
        );
         console.log("MADE IT HERE 1")

        if (userData == null) {
          return null; 
        }

        const weekScoreTotalsNotFinal = [...Array(finalWkNum)].map(
          (empty, wk) => {
            const week = wk + 1;
            const wkData = userData.find((eachWk) => {
              return week === eachWk.weekNum;
            });
            if (typeof wkData === 'undefined') {
              return {
                week,
                totalPointsWon: 0,
              };
            } 
            // else if (week === 2) {
            //   return {
            //     week,
            //     totalPointsWon: 0,
            //   };
            // } 
            else if (week > 18) {
              return {
                week,
                totalPointsWon: 0,
              };
            } else if (typeof wkData !== 'undefined') {
              return {
                week: wkData.weekNum,
                totalPointsWon: wkData.totalPointsWon,
              };
            } else return null;
          }
        );
        const currentWeekScoreTotals = userData.find((eachWk) => nflWkNum === eachWk.weekNum);
        

        // console.log(
        //   'user data',
        //   userData,
        //   'CURRENTWEEKSCORETOTALS',
        //   currentWeekScoreTotals,
        //   "NFL WEEK NUM: ",
        //   nflWkNum
        // );

        const currentWkData =
          typeof currentWeekScoreTotals === 'undefined'
            ? {
                week: nflWkNum,
                totalPointsWon: 0,
                droppedWeek: true,
              }
            : {
                week: currentWeekScoreTotals.weekNum,
                totalPointsWon: currentWeekScoreTotals.totalPointsWon,
                droppedWeek: false,
              };
              

              
        sortScoresDesc(weekScoreTotalsNotFinal, 'totalPointsWon');
        const weekScoreTotals = weekScoreTotalsNotFinal.map(
          (eachWk, ind, arr) => {
            if (nflWkNum === eachWk.week) {
              return {
                ...eachWk,
                droppedWeek: true,
              };
            } else if (groupPreferences.droppedWeeks >= arr.length) {
              return {
                ...eachWk,
                droppedWeek: false,
              };
            } else if (groupPreferences.droppedWeeks < arr.length) {
              if (ind < arr.length - groupPreferences.droppedWeeks) {
                return {
                  ...eachWk,
                  droppedWeek: false,
                };
              } else if (ind >= arr.length - groupPreferences.droppedWeeks) {
                return {
                  ...eachWk,
                  droppedWeek: true,
                };
              }
            }
          }
        );
    

        weekScoreTotals.push(currentWkData);
         
        const totalScoreFilter = weekScoreTotals.filter(
          (each) => each.droppedWeek === false
        );

        const seasonPointTotal = totalScoreFilter.reduce((acc, cv) => {
          return acc + cv.totalPointsWon;
        }, 0);
        const final = { teamName, seasonPointTotal, userId, weekScoreTotals };
        return final;
      });

     
      const promiseList = standingsData.map((pick) => {
        return pick;
      });
      const preFinalStandingsData = await Promise.all([...promiseList]);
      const finalStandingsData = preFinalStandingsData.filter((each) => {
        return each != null; 
      })
      console.log("FINAL FINAL STANDINGS: ", finalStandingsData)
      sortScoresDesc(finalStandingsData, 'seasonPointTotal');
      console.log(`searcehd for ${groupId} and year: ${seasonYear}`);
      db.collection(`standings`)
        .where('groupId', '==', groupId)
        .where('seasonYear', '==', seasonYear)
        .limit(1)
        .get()
        .then((queryData) => {
          if (queryData.empty === false) {
            queryData.docs[0].ref.update({
              standings: finalStandingsData,
              lastUpdate: new Date(),
            });
          } else if (queryData.empty) {
            db.collection(`standings`).add({
              standings: finalStandingsData,
              groupId,
              seasonYear,
              lastUpdate: new Date(),
            });
          }
        });
    }
  } catch (error) {
    console.log("ERROR: ", error)
    alert(`error loading standings page. Please refresh and try again, if this
  does not work then please take note of the error message and text me or email me.
  ERROR message is: ${error}`);
  }
};

//END---------------------------------------------------------------------------------

//START
export const updateFfcGuide = async (seasonYear, weekNum) => {
  db.doc(`colAdditionalGameData/docOdds`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return null;
      } else {
        doc.ref.set({ info: 'stores for odds info by season' });
        return null;
      }
    });

  const weekOddsData = await db
    .collection(`colAdditionalGameData/docOdds/${seasonYear}`)
    .where('week', '==', weekNum)
    .limit(1)
    .get()
    .then(async (dataSnapshot) => {
      const oddData = dataSnapshot.docs.map(async (eachItem) => {
        return eachItem.data();
      });
      const finalValues = await Promise.all(
        oddData.map((i) => {
          return i;
        })
      );
      return finalValues[0];
    });
  delete weekOddsData.week;
  const weekValues = Object.values(weekOddsData);

  const sortedOddsData = sortScoresAsc(weekValues, 'spread');
  const addedFfcGuidePts = sortedOddsData.map((eachGame, i) => {
    const gameValues = eachGame;
    if (typeof gameValues !== 'number') {
      gameValues.ffcSuggestedPoints = i + 1;
    }

    return gameValues;
  });

  const finalForm = addedFfcGuidePts.map((each) => {
    return { [each.gameId]: each };
  });

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {});

  const final = arrayToObject(addedFfcGuidePts, 'gameId');
  final.week = weekNum;

  db.collection(`colAdditionalGameData/docOdds/${seasonYear}`)
    .where('week', '==', weekNum)
    .get()
    .then((snapShot) => {
      if (snapShot.empty === false) {
        snapShot.docs[0].ref.update({ ...final });
      }
    });
};
//END
//START------------------------------------------------------------------------
export const getHighlightGames = async (groupId, weekNum, seasonYear) => {
  const highlightData = await db
    .collection(`colAdditionalGameData/docHighlightGames/season-${seasonYear}/`)
    .where('groupId', '==', groupId)
    .where('seasonYear', '==', seasonYear)
    .where('week', '==', weekNum)
    .get()
    .then((queryData) => {
      if (queryData.empty === false) {
        return queryData.docs[0].data().highlightGames;
      } else return null;
    });
  return highlightData;
};
//END------------------------------------------------------------------------

//START------------------------------------------------------------------------
export const updateWeeklyDataFromUserPickSelections = async (
  userId,
  groupId,
  seasonYear,
  allHighlightGameData
) => {
  const gameResults = await db
    .collection(`colAdditionalGameData/docGameResults/season-${seasonYear}`)
    .get()
    .then((eachWeek) => {
      return eachWeek.docs.map((d) => d.data());
    });

  const groupPreferences = await db
    .collection(`groupIds`)
    .where('groupId', '==', groupId)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty === false) {
        return querySnapshot.docs[0].data().preferences;
      }
    });

  db.collection(`userPickSelections/${userId}/season-${seasonYear}`)
    .get()
    .then((userCollectionListOfWeeks) => {
      userCollectionListOfWeeks.forEach(async (weekNumOfPickCollection) => {
        console.log('USERID', userId);
        const weekNum = weekNumOfPickCollection.get(`week`);
        const isWeekFinal = await db
          .collection(
            `colAdditionalGameData/docWeekStatus/season-${seasonYear}/`
          )
          .where('week', '==', weekNum)
          .get()
          .then((res) => {
            if (res.empty === false) {
              const wkFinal = res.docs[0].get(`weekFinal`);
              if (typeof wkFinal !== 'undefined') {
                return wkFinal;
              } else {
                db.collection(
                  `colAdditionalGameData/docWeekStatus/season-${seasonYear}`
                )
                  .doc(`week${weekNum}`)
                  .set(
                    {
                      week: weekNum,
                      weekFinal: false,
                    },
                    { merge: true }
                  );
                return false;
              }
            } else if (res.empty) {
              db.collection(
                `colAdditionalGameData/docWeekStatus/season-${seasonYear}`
              )
                .doc(`week${weekNum}`)
                .set(
                  {
                    week: weekNum,
                    weekFinal: false,
                  },
                  { merge: true }
                );
              return false;
            } else {
              return false;
            }
          });

        const highlightWeekFind = allHighlightGameData.find((highWk) => {
          return highWk.week === weekNum;
        });

        const highlightGames =
          typeof highlightWeekFind === 'undefined'
            ? []
            : highlightWeekFind?.highlightGames;
        weekNumOfPickCollection.ref
          .collection(`userPicks`)
          .get()
          .then((allPicksFromWeek) => {
            if (allPicksFromWeek.empty === false) {
              const pickData = allPicksFromWeek.docs.map((eachPick) => {
                return eachPick.data();
              });

              const findGameResultsForWeek = gameResults.find(
                (wk) => wk.week === weekNum
              );
              const arrayOfWinningIds =
                typeof findGameResultsForWeek !== 'undefined'
                  ? findGameResultsForWeek?.arrayOfWinningIds
                  : null;
              const arrayOfLosingIds =
                typeof findGameResultsForWeek !== 'undefined'
                  ? findGameResultsForWeek?.arrayOfLosingIds
                  : null;
              if (arrayOfWinningIds !== null && arrayOfLosingIds !== null) {
                const userWinsAndPoints = pickData.map((eachPick) => {
                  const highlightGamePick = highlightGames.find((pk) => {
                    return pk.gameId === eachPick.gameId;
                  });

                  const selectedTeamId = eachPick.selectedTeamId;
                  const selectedPoints = eachPick.selectedPoints;
                  const isHighlightGame =
                    typeof highlightGamePick === 'undefined' ? false : true;
                  const multiplier =
                    typeof highlightGamePick === 'undefined'
                      ? 1
                      : highlightGamePick.multiplier;
                  const pointPlacement = { selectedTeamId, selectedPoints };

                  if (
                    arrayOfWinningIds.includes(selectedTeamId) &&
                    isHighlightGame === false
                  ) {
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isHighlightGame,
                      wonGame: true,
                      pointsWon: selectedPoints,
                      selectedTeamId,
                      pointPlacement,
                      pointsLost: 0,
                      bonusPointsWon: 0,
                      totalPointsWon: selectedPoints,
                    };
                  }

                  if (
                    arrayOfWinningIds.includes(selectedTeamId) &&
                    isHighlightGame === true
                  ) {
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isHighlightGame,
                      wonGame: true,
                      pointsWon: selectedPoints,
                      pointPlacement,
                      selectedTeamId,
                      pointsLost: 0,
                      bonusPointsWon:
                        selectedPoints * multiplier - selectedPoints,
                      totalPointsWon: selectedPoints * multiplier,
                    };
                  } else if (
                    arrayOfLosingIds.includes(selectedTeamId) &&
                    isHighlightGame
                  ) {
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isHighlightGame,
                      wonGame: false,
                      pointsWon: 0,
                      selectedTeamId,
                      pickLost: false,
                      pointPlacement,
                      pointsLost: groupPreferences?.losePointsOnHighlightGames
                        ? selectedPoints
                        : 0,
                      bonusPointsWon: 0,
                      totalPointsWon: selectedPoints,
                    };
                  } else if (arrayOfLosingIds.includes(selectedTeamId)) {
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isHighlightGame,
                      wonGame: false,
                      pointsWon: 0,
                      selectedTeamId,
                      pointPlacement,
                      pointsLost: 0,
                      bonusPointsWon: 0,
                      totalPointsWon: 0,
                    };
                  } else if (
                    arrayOfLosingIds.includes(selectedTeamId) === false &&
                    arrayOfWinningIds.includes(selectedTeamId) === false &&
                    isWeekFinal &&
                    arrayOfWinningIds.includes(null) === true
                  ) {
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isHighlightGame,
                      wonGame: null,
                      pointsWon: 0,
                      selectedTeamId,
                      pointsLost: selectedPoints,
                      bonusPointsWon: 0,
                      totalPointsWon: null,
                      pickLost: true,
                      info:
                        'game either postponed or cancelled for current week; pts lost',
                    };
                  } else if (
                    arrayOfLosingIds.includes(selectedTeamId) === false &&
                    arrayOfWinningIds.includes(selectedTeamId) === false &&
                    isWeekFinal === false
                  ) {
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isHighlightGame,
                      pointsWon: 0,
                      selectedTeamId,
                      pointsLost: 0,
                      bonusPointsWon: 0,
                      totalPointsWon: 0,
                      pickLost: false,
                      info: 'game awaiting completiong',
                    };
                  } else if (
                    arrayOfWinningIds.includes(0) ||
                    arrayOfLosingIds.includes(0)
                  ) {
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isTieGame: true,
                      isHighlightGame,
                      wonGame: null,
                      pointsWon: 0,
                      selectedTeamId,
                      pointsLost: null,
                      bonusPointsWon: 0,
                      totalPointsWon: null,
                      pickLost: null,
                      info: 'Tie Game',
                    };
                  } else
                    return {
                      selectedPoints,
                      isWeekFinal,
                      isHighlightGame,
                      pointsWon: 0,
                      selectedTeamId,
                      pointsLost: 0,
                      bonusPointsWon: 0,
                      totalPointsWon: 0,
                      pickLost: false,
                      info: "I DON'T know what happened",
                    };
                });
                const bonusPointsWonInWeek = userWinsAndPoints.reduce(
                  (acc, cVal) => {
                    return acc + cVal.bonusPointsWon;
                  },
                  0
                );

                const filterBiggestBonusGameOfTheWeek = userWinsAndPoints.filter(
                  (game) => {
                    return game.bonusPointsWon > 0;
                  }
                );
                const arrayOfBiggestBonusGameOfTheWeek = filterBiggestBonusGameOfTheWeek.map(
                  (each) => {
                    return each.bonusPointsWon;
                  }
                );

                const selectedPointsTotal = userWinsAndPoints.reduce(
                  (acc, cv) => {
                    return acc + cv.selectedPoints;
                  },
                  0
                );
                const amtPointsInWeek =
                  groupPreferences?.gamesPicked === 10 ? 55 : null;
                const lostPointsByNotUsingAllPicks = userWinsAndPoints[0]
                  .isWeekFinal
                  ? amtPointsInWeek - selectedPointsTotal
                  : 0;
                const biggestBonusGameOfTheWeek =
                  arrayOfBiggestBonusGameOfTheWeek.length > 0
                    ? Math.max(...arrayOfBiggestBonusGameOfTheWeek)
                    : 0;
                const winsArray = userWinsAndPoints.filter(
                  (game) => game.wonGame === true
                );
                const lossArray = userWinsAndPoints.filter(
                  (game) => game.wonGame === false
                );
                const pickedCancelledGameLostPointsFilter = userWinsAndPoints.filter(
                  (game) => game.pickLost === true
                );
                const lostPointsBySelectingCancelledGame = pickedCancelledGameLostPointsFilter.reduce(
                  (acc, cv) => {
                    return acc + cv.pointsLost;
                  },
                  0
                );
                const wins = winsArray.length;

                //TIE GAME STATS
                const tieGamesFilter = userWinsAndPoints.filter(
                  (each) => each.isTieGame === true
                );
                const lostPointsFromTieGame = tieGamesFilter.reduce(
                  (acc, cv) => {
                    return acc + cv.selectedPoints;
                  },
                  0
                );
                const arrayOfBiggiestLossOfTieGamePoints = tieGamesFilter.map(
                  (game) => {
                    return game.selectedPoints;
                  }
                );
                const gamesTie = tieGamesFilter.length;
                const biggestLossOfPointsFromTieGame =
                  arrayOfBiggiestLossOfTieGamePoints.length > 0
                    ? Math.max(...arrayOfBiggiestLossOfTieGamePoints)
                    : 0;

                //POINTS LOST STATS
                const losses = lossArray.length;
                const pointsLostFilter = userWinsAndPoints.filter(
                  (each) => each.pointsLost > 0
                );
                const pointsLost = pointsLostFilter.reduce((acc, cv) => {
                  return acc + cv.pointsLost;
                }, 0);

                //POINTS WON STATS
                const pointsWonFilter = userWinsAndPoints.filter(
                  (each) => each.pointsWon > 0
                );
                const pointsWon = pointsWonFilter.reduce((acc, cv) => {
                  return acc + cv.pointsWon;
                }, 0);

                const pointsPlacedOnTeams = userWinsAndPoints.map((each) => {
                  return {
                    selectedTeamId: each.selectedTeamId,
                    selectedPoints: each.selectedPoints,
                  };
                });
                const totalPointsWon =
                  pointsWon + bonusPointsWonInWeek - pointsLost;

                const userStatsRef = db.collection(`userStats`);

                if (
                  typeof pickData !== 'undefined' &&
                  pickData.length >= 1 &&
                  typeof isWeekFinal !== 'undefined'
                ) {
                  userStatsRef
                    .where('userId', '==', userId)
                    .get()
                    .then((querySnapshot) => {
                      if (querySnapshot.empty === true) {
                        userStatsRef
                          .add({
                            userId,
                          })
                          .then((newUserStatDoc) => {
                            db.collection(`${newUserStatDoc.path}/NFL`)
                              .add({
                                groupId: 1,
                                seasonYear: seasonYear,
                                userId,
                                week: weekNum,
                                mostBonusPointsWon: biggestBonusGameOfTheWeek,
                                gamesWon: wins,
                                gamesLost: losses,
                                bonusPointsWon: bonusPointsWonInWeek,
                                totalPointsWon,
                                pointsWon,
                                pointsLost,
                                pointsPlacedOnTeams,
                                gamesTie,
                                biggestLossOfPointsFromTieGame,
                                lostPointsFromTieGame,
                                lostPointsBySelectingCancelledGame,
                                lostPointsByNotUsingAllPicks,
                                isWeekData: true,
                                lastUpdate: new Date(),
                              })
                              .catch((err) => {
                                alert('error adding data');
                              });
                          });
                      } else if (querySnapshot.empty === false) {
                        querySnapshot.docs[0].ref
                          .collection(`NFL`)
                          .where('groupId', '==', groupId)
                          .where('seasonYear', '==', seasonYear)
                          .where('week', '==', weekNum)
                          .limit(1)
                          .get()
                          .then((docUserStats) => {
                            if (docUserStats.empty) {
                              querySnapshot.docs[0].ref
                                .collection(`NFL`)
                                .add({
                                  groupId: 1,
                                  seasonYear: seasonYear,
                                  userId,
                                  week: weekNum,
                                  mostBonusPointsWon: biggestBonusGameOfTheWeek,
                                  gamesWon: wins,
                                  gamesLost: losses,
                                  bonusPointsWon: bonusPointsWonInWeek,
                                  totalPointsWon,
                                  pointsWon,
                                  pointsLost,
                                  pointsPlacedOnTeams,
                                  gamesTie,
                                  biggestLossOfPointsFromTieGame,
                                  lostPointsFromTieGame,
                                  lostPointsBySelectingCancelledGame,
                                  lostPointsByNotUsingAllPicks,
                                  isWeekData: true,
                                  lastUpdate: new Date(),
                                })
                                .catch((err) => {
                                  alert('error adding data');
                                });
                            } else if (docUserStats.empty === false) {
                              docUserStats.docs[0].ref
                                .update({
                                  mostBonusPointsWon: biggestBonusGameOfTheWeek,
                                  gamesWon: wins,
                                  gamesLost: losses,
                                  bonusPointsWon: bonusPointsWonInWeek,
                                  totalPointsWon,
                                  pointsWon,
                                  pointsLost,
                                  pointsPlacedOnTeams,
                                  gamesTie,
                                  biggestLossOfPointsFromTieGame,
                                  lostPointsFromTieGame,
                                  lostPointsBySelectingCancelledGame,
                                  lostPointsByNotUsingAllPicks,
                                  isWeekData: true,
                                  lastUpdate: new Date(),
                                })
                                .catch((err) => {
                                  alert('error updating data');
                                });
                            }
                          });
                      }
                    });
                }
              }
            }
          });
      });
    });
};
//END------------------------------------------------------------------------

//START
/** FUNCTION updateTotalsFromUserStats
 * 1) retrieves previously saved user weekly totals placed by running FUNC updateWeeklyDataFromUserPickSelections()
 * 2) takes all weekly totals and adds them up making total stats from the selected season
 * 3) data is updated/added back into database using isSeasonData===true search in NFL collection

 */

export const updateTotalsFromUserStats = async (
  userId,
  groupId,
  seasonYear
) => {
  const userStatsRef = db.collection(`userStats`);
  const nflWeekStatus = await db
    .doc(`/colAdditionalGameData/docWeeks/season-${seasonYear}/weekStatus`)
    .get()
    .then((res) => {
      return res.data();
    });

  const currentNflWeek =
    nflWeekStatus.currentFinalWeek === nflWeekStatus.currentNflWeekNum
      ? null
      : nflWeekStatus.currentNflWeekNum;

  const groupPreferences = await db
    .collection(`groupIds`)
    .where('groupId', '==', groupId)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty === false) {
        return querySnapshot.docs[0].data().preferences;
      } else return null;
    });
  if (groupPreferences !== null) {
    userStatsRef
      .where('userId', '==', userId)
      .get()
      .then((docUserStats) => {
        // console.log('DOC USER STATE', docUserStats.docs[0].data());
        if (docUserStats.empty === true) {
          alert('NO DATA TO UPDATE');
        } else {
          docUserStats.docs[0].ref
            .collection(`NFL`)
            .where('groupId', '==', groupId)
            .where('seasonYear', '==', seasonYear)
            .where('isWeekData', '==', true)
            .get()
            .then((weeklyGameDocs) => {
              const wkData = weeklyGameDocs.docs.map((wk) => {
                return wk.data();
              });

              const totalGamesWon = wkData.reduce((acc, cv) => {
                return acc + cv.gamesWon;
              }, 0);
              const totalGamesLost = wkData.reduce((acc, cv) => {
                return acc + cv.gamesLost;
              }, 0);
              const totalGamesTied = wkData.reduce((acc, cv) => {
                return acc + cv.gamesTie;
              }, 0);
              // mostBonusPointsWon: biggestBonusGameOfTheWeek,
              // gamesWon: wins,
              // gamesLost: losses,
              // bonusPointsWon: bonusPointsWonInWeek,
              // totalPointsWon,
              // pointsWon,
              // pointsLost,
              // pointsPlacedOnTeams,
              // gamesTie,
              // biggestLossOfPointsFromTieGame,
              // lostPointsFromTieGame,
              // lostPointsBySelectingCancelledGame,
              // lostPointsByNotUsingAllPicks,
console.log("GROUP PREF", groupPreferences)
              const sortedArrayOfScoresDesc =
                wkData.length > groupPreferences?.droppedWeeks
                  ? sortScoresDesc(wkData, 'totalPointsWon')
                  : null;
              const arrayOfWeekNum = wkData.map((wk) => {
                return wk.week;
              });

              const arrayOfWeeksInPlayWithoutCurrentNflWeek =
                sortedArrayOfScoresDesc === null
                  ? sortedArrayOfScoresDesc
                  : sortScoresDesc(
                      sortedArrayOfScoresDesc.filter((each) => {
                        return each.week !== currentNflWeek;
                      }),
                      'totalPointsWon'
                    );

              const arrayOfWeeksInPlay =
                arrayOfWeeksInPlayWithoutCurrentNflWeek === null
                  ? wkData
                  : arrayOfWeeksInPlayWithoutCurrentNflWeek.slice(
                      0,
                      arrayOfWeeksInPlayWithoutCurrentNflWeek.length -
                        groupPreferences.droppedWeeks
                    );
              const adjustedGamesWon = arrayOfWeeksInPlay.reduce((acc, cv) => {
                return acc + cv.gamesWon;
              }, 0);
              const adjustedGamesLost = arrayOfWeeksInPlay.reduce((acc, cv) => {
                return acc + cv.gamesLost;
              }, 0);
              const adjustedGamesTied = arrayOfWeeksInPlay.reduce((acc, cv) => {
                return acc + cv.gamesTie;
              }, 0);
              const totalBonusPointsWon = wkData.reduce((acc, cv) => {
                return acc + cv.bonusPointsWon;
              }, 0);
              const totalLostPointsFromTieGame = wkData.reduce((acc, cv) => {
                return acc + cv.lostPointsFromTieGame;
              }, 0);
              const totalLostPointsBySelectingCancelledGame = wkData.reduce(
                (acc, cv) => {
                  return acc + cv.lostPointsBySelectingCancelledGame;
                },
                0
              );
              const totalLostPointsByNotUsingAllPicks = wkData.reduce(
                (acc, cv) => {
                  return acc + cv.lostPointsByNotUsingAllPicks;
                },
                0
              );

              const biggestBonusGameOfTheWeekFilter = wkData.filter(
                (each) => each.mostBonusPointsWon > 0
              );
              const arrayOfBiggestBonusPoints = biggestBonusGameOfTheWeekFilter.map(
                (each) => each.mostBonusPointsWon
              );
              const biggestBonusGameOfTheWeek =
                arrayOfBiggestBonusPoints.length > 0
                  ? Math.max(...arrayOfBiggestBonusPoints)
                  : 0;
              const arrayOfWeekNumInTotalScore = arrayOfWeeksInPlay.map(
                (each) => {
                  return each.week;
                }
              );
              const arrayOfWeekNumNotIncludedInScore = arrayOfWeekNum.map(
                (eachNum) => {
                  const doesCount = arrayOfWeekNumInTotalScore.includes(eachNum)
                    ? true
                    : false;
                  if (doesCount === false) {
                    return eachNum;
                  } else return null;
                }
              );
              const arrayOfWeekNumNotInTotalScore = arrayOfWeekNumNotIncludedInScore.filter(
                (each) => {
                  return each !== null;
                }
              );
              const pointsWon = wkData.reduce((acc, cv) => {
                return acc + cv.pointsWon;
              }, 0);

              const pointsLost = wkData.reduce((acc, cv) => {
                return acc + cv.pointsLost;
              }, 0);

              const totalPointsWon = wkData.reduce((acc, cv) => {
                return acc + cv.totalPointsWon;
              }, 0);

              const adjustedPointsWon = arrayOfWeeksInPlay.reduce((acc, cv) => {
                return acc + cv.totalPointsWon;
              }, 0);

              const adjustedPointsLost = arrayOfWeeksInPlay.reduce(
                (acc, cv) => {
                  return acc + cv.pointsLost;
                },
                0
              );

              console.log(
                'YES',
                wkData,
                groupId,
                userId,
                totalGamesLost,
                totalGamesTied,
                totalGamesWon,
                adjustedGamesLost,
                adjustedGamesTied,
                adjustedGamesWon,
                totalLostPointsByNotUsingAllPicks,
                totalLostPointsBySelectingCancelledGame,
                totalLostPointsFromTieGame,
                totalBonusPointsWon,
                biggestBonusGameOfTheWeek
              );
              docUserStats.docs[0].ref
                .collection(`NFL`)
                .where('groupId', '==', groupId)
                .where('userId', '==', userId)
                .where('isSeasonData', '==', true)
                .where('seasonYear', '==', seasonYear)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.empty === true) {
                    docUserStats.docs[0].ref.collection(`NFL`).add({
                      groupId,
                      userId,
                      seasonYear,
                      isSeasonData: true,
                      totalGamesLost,
                      totalGamesTied,
                      totalGamesWon,
                      totalPointsWon,
                      arrayOfWeekNumInTotalScore,
                      arrayOfWeekNumNotInTotalScore,
                      pointsWon,
                      pointsLost,
                      adjustedPointsLost,
                      adjustedPointsWon,
                      adjustedGamesLost,
                      adjustedGamesTied,
                      adjustedGamesWon,
                      totalLostPointsByNotUsingAllPicks,
                      totalLostPointsBySelectingCancelledGame,
                      totalLostPointsFromTieGame,
                      totalBonusPointsWon,
                      biggestBonusGameOfTheWeek,
                      lastUpdate: new Date(),
                    });
                  } else if (querySnapshot.empty === false) {
                    querySnapshot.docs[0].ref.update({
                      groupId,
                      userId,
                      seasonYear: seasonYear,
                      isSeasonData: true,
                      totalGamesLost,
                      totalGamesTied,
                      totalGamesWon,
                      totalPointsWon,
                      arrayOfWeekNumInTotalScore,
                      arrayOfWeekNumNotInTotalScore,
                      pointsWon,
                      pointsLost,
                      adjustedPointsLost,
                      adjustedPointsWon,
                      adjustedGamesLost,
                      adjustedGamesTied,
                      adjustedGamesWon,
                      totalLostPointsByNotUsingAllPicks,
                      totalLostPointsBySelectingCancelledGame,
                      totalLostPointsFromTieGame,
                      totalBonusPointsWon,
                      biggestBonusGameOfTheWeek,
                      lastUpdate: new Date(),
                    });
                  }
                });
            });
        }
      });
  }
};
//END---------------------------------------------------

export default updateGameResults;
