import React from "react";
import "./sign-in-and-sign-up.styles.scss";
import FormInput from "../../components/form-input/form-input";
import FullButton, {
  BackButton
} from "../../styled-components/styled-components";
import googleSignInLogo from "../../assets/google_signin_buttons/web/vector/btn_google_dark_normal_ios.svg";
import { Link } from "react-router-dom";
import { signInWithGoogle, auth } from "../../firebase/firebase.utils";

class SignInAndSignUpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      accountOptions: false
    };
  }

  toggleAccountOptions = () => {
    this.setState({ accountOptions: !this.state.accountOptions });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { email, password } = this.state;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      this.setState({ email: "", password: "" });
    } catch (error) {
      alert("Password or email incorrect. Please try again. ");
    }
  };

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const startPage = (
      <div className="sisu__start-page">
        <p className="sisu__title">Log In</p>
        <div className="sisu__options">
          <button
            className="sisu__google-button"
            onClick={signInWithGoogle}
            isGoogleSignIn
          >
            <img src={googleSignInLogo} alt="logo" />
            <p>Sign In With Google</p>
          </button>

          <p className="sisu__horizontal-border">
            <span>OR</span>
          </p>

          <form className="sisu__form" onSubmit={this.handleSubmit}>
            <FormInput
              name="email"
              type="email"
              value={this.state.email}
              label="email"
              handleChange={this.handleChange}
              required
            />

            <FormInput
              name="password"
              type="password"
              label="password"
              value={this.state.password}
              handleChange={this.handleChange}
              required
            />

            <FullButton as="button" type="submit" loginPage>
              Login
            </FullButton>
          </form>
          <div className="sisu__account-create">
            <p>Need to Create an Account?</p>
            <button onClick={this.toggleAccountOptions} loginPage>
              Sign Up
            </button>
          </div>
        </div>
      </div>
    );

    const accountOptions = (
      <div className="sisu__start-page">
        <p className="sisu__title">Sign Up</p>
        <div className="sisu__options">
          <button
            className="sisu__google-button"
            onClick={signInWithGoogle}
            loginButton
          >
            <img src={googleSignInLogo} alt="logo" />
            <p>Sign Up With Google</p>
          </button>

          <FullButton as={Link} to="/signInAndSignUpPage/signUpPage" loginPage>
            Sign Up with E-mail
          </FullButton>
          <BackButton onClick={this.toggleAccountOptions}>
            Back to Login
          </BackButton>
        </div>
      </div>
    );

    return (
      <div className="sisu">
        {this.state.accountOptions ? accountOptions : startPage}
      </div>
    );
  }
}

export default SignInAndSignUpPage;
