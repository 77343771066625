import React from "react";
import FormInput from "../form-input/form-input";
import { BackButton } from "../../styled-components/styled-components";
import { Link } from "react-router-dom";
import { auth, createUserProfileDocument } from "../../firebase/firebase.utils";
import "./sign-up.styles.scss";

class SignUp extends React.Component {
  constructor() {
    super();

    this.state = {
      teamName: "",
      email: "",
      password: "",
      confirmPassword: ""
    };
  }

  handleSubmit = async event => {
    event.preventDefault();

    const { teamName, email, password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      alert("passwords don't match");
      return;
    }

    if (teamName.length < 4) {
      alert("Team Name must be longer than 3 characters");
      return;
    }

    if (typeof teamName === "undefined") {
      alert("Undefined Team Name");
      return;
    }

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      await createUserProfileDocument(user, teamName);

      this.setState({
        teamName: "",
        email: "",
        password: "",
        confirmPassword: ""
      });
    } catch (error) {
      alert(`JPS Sign Up Error: ${error.message}`);
    }
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    const { teamName, email, password, confirmPassword } = this.state;
    return (
      <div className="sign-up">
        <span className="description">Sign Up</span>
        <h3>Create an account with any email address</h3>

        <form className="sign-up-form" onSubmit={this.handleSubmit}>
          <FormInput
            type="text"
            name="teamName"
            value={teamName}
            onChange={this.handleChange}
            label="Team Name"
            required
          />
          <FormInput
            type="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            label="Email"
            required
          />
          <FormInput
            type="password"
            name="password"
            value={password}
            onChange={this.handleChange}
            label="Password"
            required
          />
          <FormInput
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={this.handleChange}
            label="Confirm Password"
            required
          />
          <div className="button-wrapper">
            <button type="submit" className="sisu__button" isSignUp>
              SIGN UP
            </button>
          </div>
        </form>
        <BackButton as={Link} to="/signInAndSignUpPage">
          Back to Login
        </BackButton>
      </div>
    );
  }
}

export default SignUp;
