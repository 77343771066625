import React from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import SignOutIcon from "../../icons/icons";
import SignOutButton from "../../components/sign-out-button/sign-out-button";
import "./navbar.styles.scss";

const NavBar = props => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <NavLink
      to="/signInAndSignUpPage"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </NavLink>
  ));

  const accountOptions = (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic-button" as={CustomToggle}>
        <SignOutIcon className="navbar__sign-out-icon" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="navbar__dropdown">
        <Dropdown.Item>
          <SignOutButton />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <div
      className={`navbar
    ${props.display === "contents" ? "navbar__display-contents" : ""}
        `}
      style={props.style}
    >
      {props.Buttons.map((button, index) => {
        return (
          <NavLink
            className={`navbar__navlink 
           
            `}
            activeClassName={`navbar__active-navlink
             ${props.isSection === true ? "navbar__navlink-section" : ""}
            `}
            to={button.path}
          >
            {`${button.title}`}
          </NavLink>
        );
      })}
      {props.signOutButton === true ? accountOptions : ""}
    </div>
  );
};

export default NavBar;
