import React from "react";
import "./footer.styles.scss";
import "../../sass/main.scss";

const footer = props => {
  return (
    <div className="footer">
      <div className="footer__wrapper">
        <p className="footer__text uc">Just Pick Sports 2022</p>

        <p className="footer__text">© 2022 by Daniel Frishman</p>
      </div>
    </div>
  );
};

export default footer;
