import React from "react";
import FormInput from "../form-input/form-input";
import Button, { BackButton } from "../../styled-components/styled-components";
import { Link } from "react-router-dom";
import { auth, signInWithGoogle } from "../../firebase/firebase.utils";

import "./sign-in.styles.scss";

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }
  handleSubmit = async event => {
    event.preventDefault();
    const { email, password } = this.state;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      this.setState({ email: "", password: "" });
    } catch (error) {
      alert("Password or email incorrect. Please try again. ");
    }
  };

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };
  render() {
    return (
      <div className="sign-in">
        <span className="sign-in__description">E-mail Sign In</span>
        <span className="sign-in__info">using your FFC E-mail account</span>
        <form onSubmit={this.handleSubmit}>
          <FormInput
            name="email"
            type="email"
            value={this.state.email}
            label="email"
            handleChange={this.handleChange}
            required
          />

          <FormInput
            name="password"
            type="password"
            label="password"
            value={this.state.password}
            handleChange={this.handleChange}
            required
          />

          <div className="sign-in__buttons">
            <Button as="button" type="submit" isEmailSignIn>
              Sign In
            </Button>

            {/* <ul>
              <li>Click Create Account if you have not created one yet</li>
            </ul> */}
          </div>
        </form>
        {/* <Button as={Link} to="/signInAndSignUpPage/signUpPage">
          Create an Account
        </Button> */}
        <BackButton as={Link} to="/signInAndSignUpPage">
          Back
        </BackButton>
        <ul>
          <li>I created my account with GOOGLE not e-mail</li>
        </ul>
        <Button onClick={signInWithGoogle} isGoogleSignIn>
          Sign In With My Google Account
        </Button>
      </div>
    );
  }
}

export default SignIn;
