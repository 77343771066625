import React from 'react';
import './form-input.styles.scss';

const FormInput = ({ handleChange, label, ...otherProps} ) => {


    return (

        <div className='group'>
<input 
className='input-form' 
onChange={handleChange} 
value='fdf'
{...otherProps} 
/>
{
    label ? 
    <label className={`${
        otherProps.value.length ? 'shrink' : ''
        } input-form-label`}>
            {label}
    </label> 
    : null
}

      </div>
    )
}

export default FormInput;