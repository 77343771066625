import React from "react";
import Card from "react-bootstrap/Card";
import "./home-page.styles.scss";

const HomePage = () => {
  return (
    <section id="homePage__main-container">
      <div className="homePage__main-content col-b">
        <section className="homePage__col-b">
          <div className="homePage__card-content">
            <Card style={{ marginBottom: "20px", border: "0px solid white" }}>
              <Card.Body>
                <Card.Title className="homePage__card-content-title">
                  Welcome to Just Pick Sports
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Sep 7, 2020
                </Card.Subtitle>
                <Card.Text>
                  This site provides opportunity to compete with friends and
                  family in an NFL pickems contest. Stay tuned to this page for
                  general updates and information.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card style={{ marginBottom: "20px" }}>
              <Card.Body>
                <Card.Title className="homePage__card-content-title">
                  Domain Name Connected
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Sep 30, 2020
                </Card.Subtitle>
                <Card.Text>
                  You can now go to <b>www.justpicksports.com</b> to make picks.
                  Previously the link I sent out was justpicksports.web.app
                </Card.Text>
                <Card.Text>
                  NOTE: if for any reason you have login trouble on
                  justpicksports.com try going to justpicksports.web.app and
                  login there. Someone had a login problem that was fixed by
                  doing that.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card style={{ marginBottom: "20px" }}>
              <Card.Body>
                <Card.Title className="homePage__card-content-title">
                  If you forget your password Remember this...
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Sep 30, 2020
                </Card.Subtitle>
                <Card.Text>
                  This applies only to those who signed up with email via a
                  NON-Google authentication method. TEXT or CALL me if you
                  forget your password and I will be able to initiate a password
                  reset email sent to the email address you provided. If you
                  signed in through Google and forget your password you will
                  need to initiate a password reset with them.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </section>
      </div>
    </section>
  );
};

export default HomePage;
