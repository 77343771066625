import React from "react";
import "./icons.styles.scss";

export const SignOutIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <title id="title">Umbrella Icon</title>
      <path d="M24 24c4.42 0 8-3.59 8-8 0-4.42-3.58-8-8-8s-8 3.58-8 8c0 4.41 3.58 8 8 8zm0 4c-5.33 0-16 2.67-16 8v4h32v-4c0-5.33-10.67-8-16-8z" />
    </svg>
  );
};

export default SignOutIcon;
