import React from "react";
import { NavLink } from "react-router-dom";
import SignOutIcon from "../../icons/icons";
import { auth } from "../../firebase/firebase.utils";
import "./sign-out-button.styles.scss";

const SignOutButton = () => {
  return (
    <NavLink
      className="sign-out-button"
      activeClassName="navbar__navlink-active-sign-out"
      onClick={() => auth.signOut()}
      to="/signInAndSignUpPage"
    >
      <p className="sign-out-button__text">SIGN OUT</p>
    </NavLink>
  );
};

export default SignOutButton;
