import React from "react";

const UserDataContext = React.createContext({
  seasonYear: null,
  nflWeekData: null,
  userWinLossSeasonRecord: null,
  userSeasonPointTotal: null,
  userWeeklyPickData: null,
  currentUser: null,
  standingsPageDataLoading: null,
  standingsPageData: null,
  userTotals: null
});

export default UserDataContext;
