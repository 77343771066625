import React from "react";
import "./sign-in-options.styles.scss";
import { Link } from "react-router-dom";
import Button, { BackButton } from "../../styled-components/styled-components";
import { signInWithGoogle } from "../../firebase/firebase.utils";

const signInOptions = props => {
  return (
    <div className="sign-in-options">
      <h1 className="sign-in-options__title">Login</h1>

      <div className="sign-in-options__button-box">
        <h3>EMAIL:</h3>
        <Button as={Link} to="/signInAndSignUpPage/signInPage">
          Login with E-mail
        </Button>
        <br></br>
        <h3>GOOGLE EMAIL SIGN IN</h3>
        <Button onClick={signInWithGoogle} isGoogleSignIn>
          Login with Google
        </Button>
      </div>
      <ul>
        <li className="li-note">NOTE:</li>
        <li>(1) Must Sign-In with same method you create your account with</li>
      </ul>
      <BackButton as={Link} to="/signInAndSignUpPage">
        Back
      </BackButton>
    </div>
  );
};

export default signInOptions;
