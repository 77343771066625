import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import checkUserCount from "./dbFirebase";

const config = {
  apiKey: "AIzaSyCzShhL1rOZMxtxUtRKphNwZxGLHMsQXag",
  authDomain: "justpicksports.firebaseapp.com",
  databaseURL: "https://justpicksports.firebaseio.com",
  projectId: "justpicksports",
  storageBucket: "justpicksports.appspot.com",
  messagingSenderId: "415544155422",
  appId: "1:415544155422:web:5e414a405ac15d4bae7d0f",
  measurementId: "G-RWRXHSL946"
};

export const createUserProfileDocument = async (userAuth, teamName) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    const teamNameSubmit =
      typeof teamName !== "undefined" ? teamName : "add Team Name...";
    const userId = await db
      .doc(`jpsCore/userIdCount`)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          const data = doc.data();
          db.collection(`jpsCore`)
            .doc(`userIdCount`)
            .update({
              count: firebase.firestore.FieldValue.increment(1)
            });
          return data.count;
        } else {
          console.log("No such document!");
          db.collection(`jpsCore`)
            .doc(`userIdCount`)
            .set({
              count: 2
            });
          return 1;
        }
      })
      .catch(function(error) {
        alert(
          "Error creating User ID; refresh page and if you have problems contact Daniel:",
          error
        );
      });

    // firebase.firestore(app).collection(`userIdCount`).doc().
    console.log("USER COUNT", checkUserCount());
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        teamName: teamNameSubmit,
        userId
      });
    } catch (error) {
      alert(`There was an error creating your account
      please retry error: ${error.message}`);
    }
  }
  return userRef;
};

const app = firebase.initializeApp(config);
export const db = firebase.firestore(app);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
