import React, { lazy, Suspense, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { auth, createUserProfileDocument } from './firebase/firebase.utils';
import SignInAndSignUpPage from './pages/signInAndSignUpPage/sign-in-and-sign-up';
import SignIn from './components/sign-in/sign-in';
import SignInOptions from './components/sign-in-options/sign-in-options';
import SignUp from './components/sign-up/sign-up';
import HomePage from './pages/homePage/home-page';
import UserDataContext from './context/user-data-context';
import Footer from './components/footer/footer';
import { db } from './firebase/firebase.utils';
import Spinner from './components/with-spinner/spinner';
import NavBar from './components/navbar/navbar';
import HorizontalBarFull from './components/HorizontalBarFull/horizontal-bar-full';
import NavLinkButton from './components/navlink-button/navlink-button';
import JpLogo from './logo/jpLogo';
const Profile = lazy(() => import(`./pages/profilePage/profile`));
const Standings = lazy(() => import(`./pages/standings/standings`));
const MakePicks = lazy(() => import(`./pages/makePicksPage/makePicksPage`));
const ScoutPage = lazy(() => import(`./pages/scoutPage/scoutPage`));
const MasterUserPage = lazy(() =>
  import(`./pages/masterUserPage/masterUserPage`)
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: '',
      joinedGroupIds: null,
      focusWeek: 1,
      seasonYear: 2022,
      nflWeekData: { nflWeekNum: null, finalWeek: null },
      userSeasonTotals: null,
      standingsPageData: null,
      standingsPageDataLoading: true,
      isProfileLoading: true,
      userTotals: null,
    };
  }

  unsubscribeFromAuth = null;

  loadStandingsPageData = async (groupId, seasonYear, nflWeekData) => {
    if (groupId !== null && typeof groupId !== 'undefined') {
      const standingsDb = await db
        .collection(`standings`)
        .where('groupId', '==', groupId)
        .where('seasonYear', '==', seasonYear)
        .limit(1)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            alert('DATA NOT FOUND');
            return [];
          } else if (querySnapshot.empty === false) {
            const standingsPageData = await querySnapshot.docs[0].get(
              `standings`
            );
            this.setState({ standingsPageData });
          }
        });
      return standingsDb;
    } else return null;
  };

  loadUserSeasonTotals = (userId, groupId, seasonYear) => {
    console.log('running user season totals');
    const userSeasonTotals = db
      .collection(`userStats`)
      .where('userId', '==', userId)
      .get()
      .then(async (queryData) => {
        if (queryData.empty === false) {
          const data = await queryData.docs[0].ref
            .collection(`NFL`)
            .where('isSeasonData', '==', true)
            .where('groupId', '==', groupId)
            .where('seasonYear', '==', seasonYear)
            .get()
            .then((queryTotal) => {
              if (queryTotal.empty) {
                return null;
              } else if (!queryTotal.empty) {
                return queryTotal?.docs[0].data();
              }
            });
          return data;
        } else return null;
      });
    this.setState({ userSeasonTotals });

    return userSeasonTotals;
  };

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot(async (snapShot) => {
          const userData = snapShot.data();
          this.setState({
            currentUser: {
              id: snapShot.id,
              ...userData,
            },
            joinedGroupIds: userData.joinedGroupIds,
          });
          this.loadNflWeekToState(this.state.seasonYear)
            .then(this.setState({ isProfileLoading: false }))
            .then((nflWeekData) => {
              this.loadStandingsPageData(
                1,
                this.state.seasonYear,
                nflWeekData
              ).then(this.setState({ standingsPageDataLoading: false }));
            });
        });
      } else {
        this.setState({ currentUser: false });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  loadNflWeekToState = async (seasonYear) => {
    const nflWeekData = await db
      .doc(`colAdditionalGameData/docWeeks/season-${seasonYear}/weekStatus/`)
      .get()
      .then((data) => {
        if (data.exists) {
          return {
            nflWeekNum: data.get(`currentNflWeekNum`),
            finalWeek: data.get(`currentFinalWeek`),
          };
        } else {
          let newData = {
            nflWeekNum: this.state.focusWeek+1,
            finalWeek: this.state.focusWeek,
          }
          data.ref.set({
           currentNflWeekNum: newData.nflWeekNum,
           currentFinalWeek: newData.finalWeek,
          },{merge: true})
          return newData;
        } 
      });
    this.setState({ nflWeekData });
    return nflWeekData;
  };

  render() {
    console.log("WEEK NUM STATS", this.state.nflWeekData.nflWeekNum)
    return (
      <div className="app-wrapper">
        <Switch>
          <UserDataContext.Provider
            value={{
              seasonYear: this.state.seasonYear,
              weekNum: this.state.focusWeek,
              nflWeekData: this.state.nflWeekData,
              userSeasonTotals: this.state.userSeasonTotals,
              currentUser: this.state.currentUser,
              profilePageFunc: {
                loadUserSeasonTotals: this.loadUserSeasonTotals,
              },
              standingsPageFunc: {
                loadStandingsPageData: this.loadStandingsPageData,
              },
              standingsPageDataLoading: this.state.standingsPageDataLoading,
              standingsPageData: this.state.standingsPageData,
              userTotals: this.state.userTotals,
            }}
          >
            {/* IF the user has signed in then the full site will display with options otherwise it will just be login page
FULL SITE after SIGNIN
*/}
            {this.state.currentUser ? (
              <Fragment>
                <HorizontalBarFull style={{ zIndex: '3' }} fixedTop>
                  <NavLink
                    to="/nfl/home"
                    className="horizontal-bar-full__navlink"
                  >
                    <b>Just Pick</b> Sports
                  </NavLink>
                  <NavBar
                    Buttons={[{ title: 'NFL', path: '/nfl/home', id: 'nfl' }]}
                    currentUser={this.state.currentUser}
                    isSection={true}
                    style={{ width: '70%', justifyContent: 'space-between' }}
                    signOutButton
                  />
                </HorizontalBarFull>
                <HorizontalBarFull style={{ zIndex: '1' }} fixedTopRowTwo>
                  {/*for the future if NFL is selected then display NAVBAR for NFL
                   */}
                  <NavBar
                    display="contents"
                    Buttons={[
                      { title: 'Profile', path: '/nfl/profile', id: 'profile' },
                      {
                        title: 'Standings',
                        path: '/nfl/standings',
                        id: 'standings',
                      },
                      {
                        title: 'Make Picks',
                        path: `/nfl/makepicks/${this.state.nflWeekData.nflWeekNum}`,
                        id: 'makePicksPage',
                      },
                      {
                        title: 'Scout',
                        path: `/nfl/scoutPage/${this.state.nflWeekData.nflWeekNum}`,
                        id: 'scoutPage',
                      },
                    ]}
                    currentUser={this.state.currentUser}
                  />

                  {this.state.currentUser.displayName === 'Daniel Frishman' ? (
                    <NavLinkButton
                      className="navbar__navlink-admin"
                      activeClassName="navbar__active-navlink"
                      to="/masterUserPage"
                    >
                      Admin Page
                    </NavLinkButton>
                  ) : (
                    ''
                  )}
                </HorizontalBarFull>
              </Fragment>
            ) : (
              //SIGNIN PAGE
              <JpLogo isLoginPage />
            )}
            {this.state.currentUser ? (
              <Route path="/nfl/home" />
            ) : (
              <Redirect to="/signInAndSignUpPage" />
            )}

            <Route
              exact
              path="/signInAndSignUpPage"
              render={() =>
                this.state.currentUser ? (
                  <Redirect to="/nfl/home" />
                ) : (
                  <SignInAndSignUpPage />
                )
              }
            />
            <Suspense fallback={<Spinner />}>
              <Route path="/nfl/standings" component={Standings} />
              <Route path="/nfl/home" component={HomePage} />
              <Route path="/nfl/profile" component={Profile} />
              <Route path="/nfl/makepicks" component={MakePicks} />
              <Route path="/masterUserPage" component={MasterUserPage} />
              <Route path="/nfl/scoutPage" component={ScoutPage} />
            </Suspense>
            <Route
              path="/signInAndSignUpPage/signInPage"
              render={() =>
                this.state.currentUser ? (
                  <Redirect to="/nfl/home" />
                ) : (
                  <SignIn />
                )
              }
            />

            <Route
              path="/signInAndSignUpPage/signUpPage"
              render={() =>
                this.state.currentUser ? (
                  <Redirect to="/nfl/profile" />
                ) : (
                  <SignUp />
                )
              }
            />

            <Route
              path="/signInAndSignUpPage/signInOptions"
              render={() =>
                this.state.currentUser ? (
                  <Redirect to="/nfl/profile" />
                ) : (
                  <SignInOptions />
                )
              }
            />
          </UserDataContext.Provider>
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
