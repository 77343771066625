import styled, { css } from "styled-components";
import "../sass/sizes/_sizes.scss";

export const FullButton = styled.button`
  border-radius: 0.3rem;
  background-color: #ff9800;
  color: white;
  font-weight: 500;
  border: 0px solid black;
  padding: 1.5rem 6rem;
  align-items: center;
  margin: 0.25rem 0rem;
  margin-bottom: ${props => (props.loginPage === true ? "2rem" : "")};
  width: ${props => (props.loginPage === true ? "30rem" : "")};
  box-sizing: border-box;
  font-size: 1.4rem;
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (max-device-width: 480px) {
    padding: 0.5rem 1rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const BackButton = styled(FullButton)`
  color: #ff9800;
  background-color: white;
  border: 0.2rem solid #ff9800;
`;

export const BlueButton = styled(FullButton)`
  color: #0d58a6;
  background-color: white;
  border: 0.2rem solid #0d58a6;
  margin: 1rem;
`;

export const ToolTipWrapper = styled.div`
  opacity: 1;
`;
export const ToolTipChildren = styled.div``;

export const ToolTipItem = styled.span`
  box-sizing: border-box;
  opacity: 0;
  position: absolute;
  top: 115%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #1f1f1f;
  padding: 2px;
  background-color: #f4f4f4;
  border-radius: 4px;
  font-size: 1.4rem;

  ${ToolTipWrapper}:hover & {
    opacity: 1;
  }
`;

export default FullButton;
