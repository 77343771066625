import React from "react";
import { NavLink } from "react-router-dom";
import "./jpLogo.styles.scss";

const JpLogo = props => (
  <div className={`jplogo ${props.isLoginPage ? "jplogo__login-page" : ""}`}>
    <p className="jplogo__title">Just Pick</p>
    <p
      className="jplogo__text"
      style={{ position: "relative", top: "-1.5rem" }}
    >
      Sports
    </p>
  </div>
);

export default JpLogo;
