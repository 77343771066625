import React from "react";
import { NavLink } from "react-router-dom";

const NavLinkButton = props => {
  return (
    <NavLink
      className={props.className}
      activeClassName={props.activeClassName}
      to={props.to}
    >
      {props.children}
    </NavLink>
  );
};

export default NavLinkButton;
